<template>
  <div class="bg">
    <a href="https://regcn.cacshow.com/2403CACcnVR/index.html#/login?ExhID=5498" target="_blank" class="login">
      <i class="icon iconfont icon-5-renyuandengji"></i>
      现场参观预登记
    </a>
    <div class="item_wrap">
      <div @click="goExhibition(0)" class="logo logo1">
        <img src="@/assets/img/navigation/cac.png" class="hov" />
        <img src="@/assets/img/navigation/cac-text.png" class="text" />
      </div>
      <div @click="goExhibition(1)" class="logo logo2">
        <img src="@/assets/img/navigation/seed.png" class="hov" />
        <img src="@/assets/img/navigation/seed-text.png" class="text" />
      </div>
      <div @click="goExhibition(2)" class="logo logo3">
        <img src="@/assets/img/navigation/Fshow.png" class="hov" />
        <img src="@/assets/img/navigation/fshow-text.png" class="text" />
      </div>
      <div @click="goExhibition(3)" class="logo logo4">
        <img src="@/assets/img/navigation/caa.png" class="hov" />
        <img src="@/assets/img/navigation/caa-text.png" class="text" style='width:93px' />
      </div>
      <div @click="goExhibition(4)" class="logo logo5">
        <img src="@/assets/img/navigation/cace.png" class="hov" />
        <img src="@/assets/img/navigation/cace-text.png" class="text" />
      </div>
      <div class="model" @click="PanoramaModal = true">
        <span>展馆分布图</span>
      </div>
    </div>
    <Modal v-model="PanoramaModal" class-name="vertical-center-modal mobile myModel" :mask-closable="false" footer-hide fullscreen>
      <div>
        <img @click="PanoramaModal=false" src="@/assets/img/layout.jpeg" style="width:90%;display:block;margin:auto" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data () {
    return {
      id: "",
      PanoramaModal: false,
    };
  },
  created () {
    document.title = "CAC线上平台";
  },
  methods: {
    ...mapActions({
      LinkHome: "linkTo/LinkHome",
    }),
    goExhibition (e) {
      switch (e) {
        case 0:
          this.id = "3bc3ed3d-d84e-40d6-be92-910905ba0576";
          this.LinkHome(this.id);
          break;
        case 1:
          this.id = "5fe797f0-a87e-46a6-ae72-48205eda9086";
          this.LinkHome(this.id);
          break;
        case 2:
          this.id = "63af2701-17c3-4fb1-b826-aca9228adcc3";
          this.LinkHome(this.id);
          break;
        case 3:
          this.id = "68da03a5-8127-43b0-903c-97ccb982c429";
          this.LinkHome(this.id);
          break;
        case 4:
          this.id = "33ffaaa0-6c0d-4878-b96e-d22573d3b038";
          this.LinkHome(this.id);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: url("../../assets/img/navigation/bg.jpg") no-repeat;
  background-size: cover;
  .item_wrap {
    position: absolute;
    width: 1091px;
    height: 390px;
    top: calc(50% + 85px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
  img {
    display: block;
  }
  .text {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
  .logo {
    width: 152px;
    cursor: pointer;
    position: absolute; 
    &:hover {
      .hov {
        transform: scale(1.1);
      }
    }
    &.logo1 {
      top: 0;
      left: 0;
      height: 202px;
    }
    &.logo2 {
      bottom: 0;
      left: 237px;
      height: 205px;
    }
    &.logo3 {
      top: 0;
      left: 470px;
      height: 199px;
    }
    &.logo4 {
      bottom: 0;
      left: 704px;
      height: 205px;
    }
    &.logo5 {
      top: 0;
      right: 0;
      height: 202px;
    }
  }
  .model {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -100px;
    color: #fff;
    font-size: 22px;
    display: flex;
    justify-content: center;
    span {
      cursor: pointer;
      padding: 0 5px;
      border-bottom: 1px solid #fff;
    }
  }
  .login {
    position: absolute;
    right: 20%;
    top: 24px;
    color: #fff;
    cursor: pointer;
    i {
      margin-right: 5px;
    }
  }
}
::v-deep {
  .ivu-modal-close {
    right: 50px;
  }
  .ivu-icon-ios-close {
    font-size: 50px;
  }
}
</style>
